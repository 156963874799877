import { AxiosStatic } from 'axios';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';

type DownloadResponse = {
  data: DownloadDataResponse
};

type DownloadDataResponse = {
  downloadURL: string
};

export async function downloadFile(token:string | undefined, path:string, retryCount = 0):Promise<AxiosStatic | undefined> {
  if (token) {
    let retry = retryCount;
    const config = getConfig(token);
    const url = '/files/download';
    try {
      const response:DownloadResponse = await OliAxios.get(url, {
        ...config, params: { path },
      });
      const { downloadURL } = response.data;
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = path;
      link.click();
    } catch (e) {
      if (retry < 2) {
        downloadFile(token, path, retry += 1);
      }
    }
  }
  return undefined;
}
